<template>
  <div>
    <b-form-group
      label="Test Sürüşü"
      label-for="testdrive"
    >
      <validation-provider
        #default="{ errors }"
        name="Test Sürüşü"
        rules="required"
      >
        <v-select
          id="testdrive"
          v-model="interview.testdrive"
          :disabled="!!interview.id_com_testdrive_form_sms"
          :options="testdrives"
          :reduce="testdrive => testdrive.id"
          label="title"
          placeholder="Seçiniz"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <b-button
      v-if="interview.testdrive > 0 && !interview.id_com_testdrive_form_sms"
      v-b-toggle.sidebar-add-TestForm
      type="submit"
      variant="danger"
      class="mb-1"
      size="sm"
      block
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-50"
      />
      Test Sürüşü Formu Oluştur
    </b-button>
    <AddTestForm v-if="interview.testdrive > 0 && !interview.id_com_testdrive_form_sms" />
  </div>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import { BButton, BFormGroup, VBToggle } from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import AddTestForm from '@/views/Interviews/components/AddTestForm.vue'

export default {
  name: 'TestDrive',
  components: {
    BButton,
    BFormGroup,
    vSelect,
    ValidationProvider,
    AddTestForm,
  },
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  data() {
    return {
      locale: 'tr',
    }
  },
  computed: {
    interview() {
      return this.$store.getters['interviews/getInterview']
    },
    testdrives() {
      return [
        {
          id: 1,
          title: 'Yapıldı',
        },
        {
          id: 0,
          title: 'Yapılmadı',
        },
      ]
    },
  },
  created() {
    localize(this.locale)
  },
}
</script>

<style scoped>

</style>
