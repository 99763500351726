<template>
  <div>
    <b-card no-body>
      <b-card-header>
        <b-card-title>
          #{{ interview.interview_number }}
          <small>{{ interview.company? interview.company + ' ' + interview.customer : interview.customer }}
            - <span class="text-warning">{{ interview.username }}</span>
          </small>
          <div
            v-if="interview.forwarded_user"
            class="justify-content-center align-items-center "
          >
            <small class="text-secondary">
              <feather-icon
                icon="RepeatIcon"
              /> Görüşme <b class="text-primary">{{ interview.forwarded_user }}</b> tarafından yönlendirildi.</small>
          </div>
        </b-card-title>
        <div>
          <b-button
            v-if="interview.status != '1' && userData.id === interview.id_com_user"
            pill
            size="sm"
            variant="flat-primary"
            class="btn-icon"
            @click="redirectModalAction"
          >
            Başka Danışmana Yönlendir
          </b-button>
          <b-button
            v-if="interview.status != '1' && userData.id === interview.id_com_user"
            pill
            size="sm"
            variant="flat-primary"
            class="btn-icon"
            :to="'/offers/add/'+interview.id_com_customer"
            target="_blank"
          >
            Araç Teklifi Oluştur
          </b-button>
        </div>
      </b-card-header>
      <b-row>
        <b-col xs="12">
          <b-table-simple
            fixed
          >
            <b-thead>
              <b-tr>
                <b-th>
                  Görüşme Tipi
                </b-th>
                <b-th>
                  Marka
                </b-th>
                <b-th>
                  Model
                </b-th>
                <b-th>
                  Test Sürüşü
                </b-th>
                <b-th>
                  İlk Temas
                </b-th>
                <b-th>
                  Durum Kodu
                </b-th>
                <b-th>
                  Durum
                </b-th>
              </b-tr>
            </b-thead>
            <b-tbody>
              <b-tr>
                <b-td>{{ interview.interview_type }}</b-td>
                <b-td>{{ interview.brand }}</b-td>
                <b-td>{{ interview.model }}</b-td>
                <b-td>{{ interview.testdrive }}</b-td>
                <b-td>{{ interview.meet }}</b-td>
                <b-td>{{ interview.status_code }}</b-td>
                <b-td>{{ interview.statusValue }}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
    </b-card>
    <redirect-modal />
  </div>
</template>
<script>
import {
  BCard, BCardTitle, BTableSimple, BThead, BTbody, BTr, BTh, BTd, BRow, BCol, BButton, BCardHeader,
} from 'bootstrap-vue'
import RedirectModal from '@/views/Interviews/components/RedirectModal.vue'

export default {
  name: 'InterviewSummary',
  components: {
    BCard,
    BCardTitle,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTh,
    BTd,
    BRow,
    BCol,
    BButton,
    BCardHeader,
    RedirectModal,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    interview() {
      return this.$store.getters['interviews/getViewInterview']
    },
    redirectModal() {
      return this.$store.getters['interviews/redirectModal']
    },
  },
  methods: {
    redirectModalAction() {
      this.redirectModal.status = !this.redirectModal.status
      if (this.redirectModal.status) {
        this.redirectModal.id_com_interview_type = null
        this.redirectModal.id_com_user = null
        this.redirectModal.content = null
      }
    },
  },
}
</script>
