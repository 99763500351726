<template>
  <div>
    <b-modal
      id="edit-modal"
      centered
      size="lg"
      title="Güncelle"
      hide-footer
      no-close-on-backdrop
    >
      <validation-observer ref="simpleRules">
        <meets />
        <status-code />
        <realty-projects />
        <realty-types />
        <interview-statuses :id_com_interview_type="interview.id_com_interview_type" />
        <interview-content />
        <save-button :action-methods="saveData" />
      </validation-observer>
    </b-modal>
  </div>
</template>
<script>
import { ValidationObserver, localize } from 'vee-validate'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'
import { BModal } from 'bootstrap-vue'
import Meets from '@/views/Interviews/components/view/edit/Meets.vue'
import RealtyProjects from '@/views/Interviews/components/view/edit/RealtyProjects.vue'
import RealtyTypes from '@/views/Interviews/components/view/edit/RealtyTypes.vue'
import InterviewStatuses from '@/views/Interviews/components/view/edit/Interview_statuses.vue'
import InterviewContent from '@/views/Interviews/components/view/edit/Content.vue'
import SaveButton from '@/views/Interviews/components/view/edit/SaveButton.vue'
import StatusCode from '@/views/Interviews/components/view/edit/StatusCode.vue'

export default {
  name: 'EditModal',
  components: {
    BModal,
    Meets,
    InterviewContent,
    RealtyProjects,
    RealtyTypes,
    InterviewStatuses,
    SaveButton,
    ValidationObserver,
    StatusCode,
  },
  computed: {
    interviewLine() {
      return this.$store.getters['interviews/getEditInterviewLine']
    },
    interview() {
      return this.$store.getters['interviews/getViewInterview']
    },
    saveStatus() {
      return this.$store.getters['interviews/getInterviewSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
        this.getInterview()
        this.$bvModal.hide('edit-modal')
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.interviewLine.submitStatus = false
    },
  },
  created() {
    localize('tr')
  },
  methods: {
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    saveData() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.interviewLine.submitStatus = true
          this.$store.dispatch('interviews/interviewSave', this.interviewLine)
        }
      })
    },
    getInterview() {
      this.$store.dispatch('interviews/interviewView', this.$route.params.interview_number)
    },
  },
}
</script>

<style scoped></style>
