<template>
  <b-sidebar
    id="sidebar-add-expertise"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Ön Fiyat Talebi
        </h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <validation-observer ref="simpleRules">
        <b-form
          class="p-2"
        >
          <div><invoice-type /></div>
          <div><brands /></div>
          <div><models v-if="swapcar.id_com_swap_brand" /></div>
          <div><hardware /></div>
          <div><model-year /></div>
          <div><km /></div>
          <div><colors /></div>
          <div><cc /></div>
          <div><hp /></div>
          <div><fuels /></div>
          <div><gears /></div>
          <div><license-plate /></div>
          <div><request-price /></div>
          <div><notes /></div>
          <div><save-button :action-methods="submitForm" /></div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>
<script>
import { ValidationObserver, localize } from 'vee-validate'
import {
  BSidebar, BForm,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import InvoiceType from '@/views/Swapcars/forms/Invoice_type.vue'
import Brands from '@/views/Swapcars/forms/Brands.vue'
import Models from '@/views/Swapcars/forms/Models.vue'
import Hardware from '@/views/Swapcars/forms/Hardware.vue'
import ModelYear from '@/views/Swapcars/forms/Model_year.vue'
import Km from '@/views/Swapcars/forms/Km.vue'
import Colors from '@/views/Swapcars/forms/Colors.vue'
import Cc from '@/views/Swapcars/forms/Cc.vue'
import Hp from '@/views/Swapcars/forms/Hp.vue'
import Fuels from '@/views/Swapcars/forms/Fuels.vue'
import Gears from '@/views/Swapcars/forms/Gears.vue'
import LicensePlate from '@/views/Swapcars/forms/License_plate.vue'
import RequestPrice from '@/views/Swapcars/forms/Request_price.vue'
import Notes from '@/views/Swapcars/forms/Notes.vue'
import SaveButton from '@/views/Swapcars/forms/SaveButton.vue'

export default {
  components: {
    BSidebar,
    BForm,
    ValidationObserver,
    InvoiceType,
    Brands,
    Models,
    Hardware,
    ModelYear,
    Km,
    Colors,
    Cc,
    Hp,
    Fuels,
    Gears,
    LicensePlate,
    RequestPrice,
    Notes,
    SaveButton,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
      locale: 'tr',
      submitStatus: false,
    }
  },
  computed: {
    interview() {
      return this.$store.getters['interviews/getInterview']
    },
    swapcar() {
      return this.$store.getters['swapcars/getSwapcar']
    },
    saveStatus() {
      return this.$store.getters['swapcars/getSwapcarSaveStatus']
    },
  },
  watch: {
    saveStatus(val) {
      if (val.status === true) {
        const config = {
          icon: 'success',
          title: 'İşlem Başarılı!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
        this.refreshData()
      } else if (val.status === false) {
        const config = {
          icon: 'error',
          title: 'İşlem Hatası!',
          text: val.message,
          html: null,
          buttonText: 'Kapat',
        }
        this.sweetAlert(config)
      }
      this.submitStatus = false
      this.swapcar.submitStatus = false
    },
  },
  created() {
    localize(this.locale)
  },
  methods: {
    sweetAlert(config) {
      this.$swal({
        icon: config.icon,
        title: config.title,
        text: config.text,
        html: config.html,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        confirmButtonText: config.buttonText,
        buttonsStyling: false,
      })
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          this.submitStatus = true
          this.swapcar.submitStatus = true
          this.swapcar.id_com_customer = this.interview.id_com_customer
          this.swapcar.interview_number = this.interview.interview_number
          this.swapcar.expertise_type = this.interview.expertise_type
          this.$store.dispatch('swapcars/swapcarRequest', this.swapcar)
            .then(res => {
              if (res.id) {
                this.interview.id_com_swapcar = res.id
              }
            })
        }
      })
    },
  },
}
</script>
