<template>
  <b-modal
    v-model="redirectModal.status"
    title="Başka Danışmana Yönlendir"
    centered
    no-close-on-backdrop
    no-close-on-esc
  >
    <b-form-group
      label="Görüşme Tipi"
      label-for="id_com_interview_type"
    >
      <v-select
        id="id_com_interview_type"
        v-model="redirectModal.id_com_interview_type"
        label="title"
        :reduce="type => type.id"
        :options="interviewTypes"
        placeholder="Seçiniz"
      />
    </b-form-group>
    <b-form-group
      label="Danışman"
      label-for="id_com_user"
    >
      <v-select
        id="id_com_user"
        v-model="redirectModal.id_com_user"
        label="name"
        :reduce="user => user.id"
        :options="users"
        placeholder="Seçiniz"
      >
        <template v-slot:option="option">
          <div v-if="option.brand">
            {{ option.name }}
            <div>
              <small class="text-secondary">{{ option.brand }} / <span class="text-primary">{{ option.department }}</span> / <span class="text-success">{{ option.type }}</span></small>
            </div>
          </div>
          <div v-else>
            {{ option.title }}
          </div>
        </template>
        <template v-slot:selected-option="option">
          <div v-if="option.brand">
            {{ option.name }}
            <small class="text-secondary">{{ option.brand }} / <span class="text-primary">{{ option.department }}</span> / <span class="text-success">{{ option.type }}</span></small>
          </div>
          <div v-else>
            {{ option.name }}
          </div>
        </template>
      </v-select>
      <small class="text-warning">Yönlendirmek istediğiniz danışmanı görüşme tipine uygun seçiniz.</small>
    </b-form-group>
    <b-form-group
      label="Görüşme Notu"
      label-for="content"
    >
      <b-form-textarea
        id="content"
        v-model="redirectModal.content"
        placeholder="Giriniz"
      />
    </b-form-group>

    <template #modal-footer>
      <b-button
        :disabled="!redirectModal.id_com_interview_type || !redirectModal.id_com_user"
        variant="success"
        @click="sendInterview"
      >
        <feather-icon icon="SaveIcon" />
        Kaydet
      </b-button>
    </template>
  </b-modal>
</template>
<script>
import {
  BButton, BFormGroup, BFormTextarea, BModal,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'RedirectModal',
  components: {
    BButton,
    BFormGroup,
    BFormTextarea,
    BModal,
    vSelect,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  computed: {
    interview() {
      return this.$store.getters['interviews/getViewInterview']
    },
    users() {
      return this.$store.getters['users/getUsers']
    },
    interviewTypes() {
      return this.$store.getters['interviewTypes/getInterview_types']
    },
    redirectModal() {
      return this.$store.getters['interviews/redirectModal']
    },
  },
  created() {
    this.getUsers()
    this.getTypes()
  },
  methods: {
    getUsers() {
      this.$store.dispatch('users/usersList', {
        select: [
          'com_user.id as id',
          'com_user.name as name',
          'com_brand.name as brand',
          'com_user_type.title as type',
          'com_department.title as department',
        ],
        where: {
          'com_user.status': 1,
          'com_user.id_com_user_type': 5,
        },
      })
    },
    getTypes() {
      this.$store.dispatch('interviewTypes/interview_typesList', {
        select: [
          'com_interview_type.id AS id',
          'com_interview_type.title AS title',
        ],
      })
    },
    sendInterview() {
      this.$swal({
        title: 'Başka Danışmana Yönlendir',
        text: 'Görüşmeyi başka danışmana yönlendirmek istiyor musunuz ? Görüşme seçtiğiniz danışmanda "Görüşme Devam Ediyor" durumunda açılacak ve bildirim gönderilecek.',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$store.state.app.removeConfirm,
        cancelButtonText: this.$store.state.app.removeCancel,
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$store.dispatch('interviews/interviewRedirect', {
            // atadığı kişinin markası da backendde yakalanacak.
            id_com_user: this.redirectModal.id_com_user,
            id_com_interview_type: this.redirectModal.id_com_interview_type,
            content: this.redirectModal.content,
            id_com_meet: this.interview.id_com_meet,
            id_com_interview_subject: this.interview.id_com_interview_subject,
            id_com_customer: this.interview.id_com_customer,
            id_com_model: this.interview.id_com_model,
            id_com_swap_brand: this.interview.id_com_swap_brand,
            id_com_swap_model: this.interview.id_com_swap_model,
            id_com_cars: this.interview.id_com_cars,
            id_com_swapcar: this.interview.id_com_swapcar,
            id_com_activity: this.interview.id_com_activity,
            id_com_realty_project: this.interview.id_com_realty_project,
            id_com_realty_type: this.interview.id_com_realty_type,
            id_com_insurance_company: this.interview.id_com_insurance_company,
          })
            .then(res => {
              if (res.status) {
                this.$swal({
                  icon: 'success',
                  title: 'İşlem Başarılı',
                  text: 'Görüşme seçtiğiniz danışmana yönlendirildi ve bildirim gönderildi. Görüşmeyi kapalı durumuna alabilirsiniz..',
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.redirectModal.status = false
              } else if (!res.status) {
                this.$swal({
                  icon: 'danger',
                  title: 'İşlem Başarısız',
                  text: res.message,
                  confirmButtonText: this.$store.state.app.removeResultClose,
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            })
        }
      })
    },
  },
}
</script>
